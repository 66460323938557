@tailwind base;
@tailwind components;
@tailwind utilities;

/* FONT IMPORTS */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Share+Tech&display=swap");

body {
  @apply font-sans font-normal;
}

@media only screen and (max-width: 1023px) {
  .menu-bg-block {
    border-right: solid;
    border-left: none;
    border-bottom: none;
    border-top: none;
    border-width: 2px;
    border-image: linear-gradient(to bottom, #e68eb190, #7991db90, #27154690) 1
      100%;
  }
}

.fade-in-down {
  animation: fadeInDown 1.5s;
}

.fade-in-left {
  animation: fadeInLeft 1.5s;
}

.fade-in-up {
  animation: fadeInUp 1.5s;
}

.fade-in {
  animation: fadeIn 1.5s;
}

.initialize-nav-items {
  animation: iconNavItems 1200ms;
}

@keyframes fadeInDown {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  80% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  80% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(600px);
    opacity: 0;
  }
  80% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes iconNavItems {
  0% {
    transform: translateY(-128px);
  }
  50% {
    transform: translateY(-128px);
  }
  100% {
    animation-duration: 300ms;
    transform: translateY(0px);
  }
}
